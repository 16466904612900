var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_vm._t("default",null,{"on":on})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"p-5 p-md-8"},[_c('ModalHeading',{attrs:{"modalTitle":_vm.formMode === 'add' ? 'Add new Comment' : 'Edit Comment'}}),_c('form',{staticClass:"form pt-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-7"},[_c('v-textarea',{attrs:{"label":"Comment","placeholder":"Enter your comment","rows":"3","outlined":""},on:{"blur":function($event){return _vm.$v.form.comment.$touch()}},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('VulidateError',{attrs:{"validationField":_vm.$v.form.comment,"params":[
              'required',
              {
                maxLength: {
                  number: _vm.$v.form.comment.$params.maxLength.max,
                },
              },
            ]}})],1)]),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-secondary font-weight-bolder font-size-h6 px-8",attrs:{"type":"button"},on:{"click":_vm.resetModal}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-info font-weight-bolder font-size-h6 px-8 ml-5",class:{
            'spinner spinner-light spinner-right': _vm.isLoadingSubmitted,
          },attrs:{"disabled":_vm.isLoadingSubmitted}},[_vm._v(" Save ")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }