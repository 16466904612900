<template>
  <div class="card card-custom gutter-b form">
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between mb-6">
        <h4 class="font-weight-bolder text-dark">
          Comments from Rec consultants and Operations on talent
        </h4>

        <b-button
          @click.prevent="showCommentModal"
          size="sm"
          variant="outline-info"
          class="px-2 ml-3"
        >
          Add new Comment
          <i class="fas fa-plus px-0"></i
        ></b-button>
      </div>

      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <!--begin::Table-->
          <!--   <button
            class="btn btn-danger mb-3"
            v-if="selectedIds.length"
            @click="confirmDeleteSelectedComments"
          >
            Delete Selected Comments
          </button> -->
          <div
            class="table-responsive mb-0"
            v-if="comments && comments.length && comments.length > 0"
          >
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
                <tr class="text-left">
                  <!--                   <th class="pr-0 pl-3">Select</th>
 -->
                  <th style="min-width: 130px">
                    <span class="text-dark-75 d-inline-flex align-items-center"
                      >Comment
                    </span>
                  </th>
                  <th style="min-width: 130px">
                    <span class="text-dark-75 d-inline-flex align-items-center">
                      User
                    </span>
                  </th>
                  <th style="min-width: 130px">
                    <span>Create Date</span>
                  </th>
                  <th style="min-width: 130px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="comment in comments" :key="comment.id">
                  <!--<td class="px-0" @click.stop>
                    <v-checkbox
                      v-model="selectedIds"
                      :value="comment.id"
                      hide-details
                      class="m-0"
                    ></v-checkbox>
                  </td> -->
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{ comment.body || "-" }}
                    </div>
                  </td>
                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{ comment.comment_by.name || "-" }}
                    </div>
                  </td>

                  <td class="font-weight-bolder">
                    <div class="d-flex align-items-center">
                      {{ showDate(comment.created_at) || "-" }}
                    </div>
                  </td>
                  <td>
                    <b-button
                      size="sm"
                      variant="outline-info"
                      class="px-2 ml-3"
                      @click="handleEditComment(comment)"
                    >
                      <i class="flaticon2-edit"></i>
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-info"
                      class="px-2 ml-3"
                      @click="handleDeleteComment(comment.id)"
                    >
                      <i class="flaticon2-trash"></i>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5
            class="my-5 text-muted text-center"
            v-if="comments && comments.length === 0"
          >
            No comments yet
          </h5>
          <div class="pagination-container">
            <p
              v-if="comments && comments.length > 0"
              class="pagination-container__desc"
            >
              {{
                $t("showing_results", {
                  length: comments.length,
                  total: commentsMeta.total,
                })
              }}
            </p>
            <b-pagination
              v-if="commentsMeta && commentsMeta.last_page > 1"
              v-model="page"
              :total-rows="commentsMeta.total"
              :per-page="perPage"
              align="center"
              last-number
              first-number
            ></b-pagination>
          </div>

          <!--end::Table-->
        </div>
      </div>
    </div>

    <div>
      <AddNewComment
        :activeComment="activeComment"
        @resetActiveComment="activeComment = null"
        :page="page"
      >
        <template #default="{ on }">
          <span ref="add-comment-modal" v-on="on"></span>
        </template>
      </AddNewComment>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddNewComment from "@/components/talent-profile/modals/AddNewComment.vue";
import dayjs from "dayjs";
import Swal from "sweetalert2";

export default {
  name: "LeaveComments",
  components: {
    AddNewComment,
  },
  data() {
    return {
      page: 1,
      perPage: 5,
      selectedIds: [],
      activeComment: null,
    };
  },
  computed: {
    ...mapGetters({
      comments: "talentProfile/getCommentsList",
      commentsMeta: "talentProfile/getCommentsMeta",
    }),
  },
  mounted() {
    this.fetchComments();
  },
  watch: {
    page() {
      this.fetchComments();
    },
  },
  methods: {
    ...mapActions({
      getTalentComments: "talentProfile/getTalentComments",
      deleteComment: "talentProfile/deleteComment",
    }),
    showCommentModal() {
      this.$refs["add-comment-modal"].click();
    },
    showDate(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm a");
    },
    fetchComments() {
      this.getTalentComments({
        talent_id: this.$route.params.id,
        page: this.page,
      });
    },
    handleEditComment(comment) {
      this.activeComment = comment;
      this.showCommentModal();
    },
    handleDeleteComment(id) {
      Swal.fire({
        title: "Are you sure",
        text: "You want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteComment({
            talent_id: this.$route.params.id,
            comment_id: id,
            page: this.page,
          });
        }
      });
    },
  },
};
</script>
