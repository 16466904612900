<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading
        :modalTitle="formMode === 'add' ? 'Add new Comment' : 'Edit Comment'"
      />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-12 mb-7">
            <v-textarea
              v-model="form.comment"
              @blur="$v.form.comment.$touch()"
              label="Comment"
              placeholder="Enter your comment"
              rows="3"
              outlined
            ></v-textarea>
            <VulidateError
              :validationField="$v.form.comment"
              :params="[
                'required',
                {
                  maxLength: {
                    number: $v.form.comment.$params.maxLength.max,
                  },
                },
              ]"
            />
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': isLoadingSubmitted,
            }"
            :disabled="isLoadingSubmitted"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import VulidateError from "@/components/shared/vulidateError.vue";
import { validationMixin } from "vuelidate";

export default {
  name: "AddNewComment",
  components: { ModalHeading, VulidateError },
  mixins: [validationMixin],
  props: {
    activeComment: {
      type: Object,
      default: null,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialog: false,
      isLoadingSubmitted: false,
      formMode: "add",
      form: {
        comment: null,
      },
    };
  },
  validations: {
    form: {
      comment: {
        required,
        maxLength: maxLength(500),
      },
    },
  },
  watch: {
    activeComment: {
      immediate: true,
      deep: true,
      handler() {
        if (this.activeComment) {
          this.formMode = "edit";
          this.form.comment = this.activeComment.body;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      addComment: "talentProfile/addComment",
      editComment: "talentProfile/editComment",
    }),
    resetModal() {
      this.$v.$reset();
      this.form = {
        comment: null,
      };
      this.formMode = "add";
      this.dialog = false;
      this.$emit("resetActiveComment")
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoadingSubmitted = true;
      if (this.formMode == "edit") {
        this.editComment({
          comment_id: this.activeComment.id,
          comment: this.form.comment,
          talent_id: this.$route.params.id,
          page: this.page,
        })
          .then(() => {
            this.isLoadingSubmitted = false;
            this.resetModal();
            this.dialog = false;
          })
          .catch(() => {
            this.isLoadingSubmitted = false;
          });
      } else {
        this.addComment({
          talent_id: this.$route.params.id,
          comment: this.form.comment,
          page: this.page,
        })
          .then(() => {
            this.isLoadingSubmitted = false;
            this.resetModal();
            this.dialog = false;
          })
          .catch(() => {
            this.isLoadingSubmitted = false;
          });
      }
    },
  },
};
</script>
